// npm
import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, BoxProps, Container, Palette, styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import CmsLinkButton from '@atoms/buttons/CmsLinkButton'
import HeadingEnd from '@atoms/text/HeadingEnd'
import Markdown from '@atoms/text/Markdown'
import SmallHeading from '@atoms/text/SmallHeading'

// constants
import { BreakpointProps } from '@constants/breakpointProps'

// helpers
import getThemeColorValue from '@helpers/get-theme-color-value'

interface BgProps {
  bgColor?: string
}

interface ImageProps extends BgProps, BreakpointProps {}

export interface SectionContentVideoProps extends BoxProps, ImageProps {
  smallHeading: string
  mainHeading: string
  text: string
  image: IGatsbyImageData
  button?: Queries.DatoCmsButton
  isFirstSection?: boolean
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}))

const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<BgProps>(({ bgColor, theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  backgroundColor: getThemeColorValue(bgColor as keyof Palette),
}))

const StyledGatsbyImage = styled(GatsbyImage, {
  shouldForwardProp: (prop) => prop !== 'breakpoint',
})<ImageProps>(({ breakpoint, theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%,0)',
  dispay: 'block',
  transition: theme.transitions.create(['opacity']),
  opacity: 0.3,
  [theme.breakpoints.up('md')]: {
    opacity: 0.4,
  },
  [theme.breakpoints.up(breakpoint)]: {
    opacity: 0.5,
  },
}))

const SectionContentVideo = ({
  text,
  smallHeading,
  mainHeading,
  image,
  button,
  bgColor,
  isFirstSection,
  ...props
}: SectionContentVideoProps) => {
  const breakpoint = 'lg'
  return (
    <Wrapper
      component="section"
      overflow="hidden"
      bgcolor={`${bgColor}`}
      {...props}
    >
      <Grid
        container
        spacing={0}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Grid
          xs={12}
          md={8}
          xl={8}
          ml="auto"
          sx={{
            minHeight: '100vh',
          }}
        >
          <ImageWrapper bgColor={bgColor}>
            <StyledGatsbyImage
              breakpoint={breakpoint}
              image={image}
              alt=""
              loading={isFirstSection ? 'eager' : 'lazy'}
            />
          </ImageWrapper>
        </Grid>
      </Grid>
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <Grid container spacing={0}>
          <Grid
            xs={12}
            sm={6}
            xl={5}
            sx={{
              minHeight: '100vh',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              {smallHeading && (
                <SmallHeading
                  heading={smallHeading}
                  headingBoxProps={{ component: 'h1' }}
                  mb={{ xs: 2, breakpoint: 3 }}
                />
              )}
              <Box
                typography="xlHeading"
                color="white"
                component="h2"
                mt={0}
                mb={{ xs: 3, breakpoint: 5 }}
              >
                {mainHeading}
                <HeadingEnd />
              </Box>
              <Markdown
                typography="lgBody"
                mb={{ xs: 3, breakpoint: 6 }}
                text={text}
              />
              {button && <CmsLinkButton button={button} />}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default memo(SectionContentVideo)
